import { Service, ServicePackage } from "@/types/service";

// packages

const buddhistFuneralPackage: ServicePackage = {
  id: "3-day-basic-package",
  name: "Buddhist  Funeral Package",
  description: "3 Days Basic Package $5500",
  includes: [
    {
      heading: "Casket and embalming",
      items: [
        "Half Glass Casket",
        "Casket Furnishing",
        [
          "Pillow",
          "Sutra Blanket",
          "Sandal Wood",
          "Sandal Powder",
          "Cotton Roll",
        ],
        "Embalming by Licensed Female Embalmer",
        [
          "Showering of Deceased",
          "Make-up for Deceased",
          "Dressing for Deceased",
        ],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "3 Session Revered Monk Prayer (Encoffinment + Final Night + Funeral Day)",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers In Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        "Air Coolers x 2 Units",
        "Purposeful Furnishing",
        "Warmth Lighting Ambiance",
        "Buddhist Themed Backdrop",
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Memorial Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "1 enlarge photo size 10″(W) X 12″(H)",
        "6 pcs passport size photo",
        "Incense Pot",
        "Joss Sticks (Small / Medium / Large)",
        "Ancestral Tablet",
        "Deceased Chair",
        "Vegetarian Food Offering x 3 Sets",
        "Oil Lamp",
        "Red Gel Candles",
      ],
    },
    {
      heading: "Buddha Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "Traditional Chinese Lamp",
        "Buddha Statue",
        "Food & Fruits Offering",
        "Buddha Incense Pot",
        "Incense Coil",
        "Lotus Gel Candles",
        "Glass Cup",
        "Oil Lamp",
      ],
    },
    {
      heading: "Other Essential",
      items: [
        "Guest Reception Area Setup",
        "Condolence Book",
        "Safe Box",
        "Stationary Set",
        "Funeral Notice",
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Appreciation Token for Guest",
        "Passport Size Photo Keepsake",
        "Ash Collection Service",
        "Post Funeral Guide",
        "Mobile Toilet",
        "Ash Collection Service with Complimentary Red Cloth and Paper Umbrella",
      ],
    },
  ],
};

const taoistFuneralPackage: ServicePackage = {
  id: "3-day-basic-package",
  name: "Taoist  Funeral Package",
  description: "3 Days Basic Package $7500",
  includes: [
    {
      heading: "Casket service",
      items: ["Half Glass Casket"],
    },
    {
      heading: "Embalming services",
      items: ["Professional Embalming, Makeup, and Dressing"],
    },
    {
      heading: "Professional Service Team",
      items: [
        "Collection of deceased from home or hospital",
        "Transportation of deceased to our embalming center",
        "Transportation of deceased from our embalming center back to void deck or funeral parlour",
        "Booking of cremation slot and administrative work",
        "Full suited funeral service crew on funeral day",
      ],
    },
    {
      heading: "Preparation of Taoist Ceremony",
      items: [
        "Void Decks",
        [
          "18″ Wake Enclosure Unit x 1",
          "Curtain Setup Unit x 1",
          "Carpet Setup Unit x 1",
          "General Lighting",
          "Round Table Unit x 10",
          "Square Table Unit x 15",
          "Chairs Unit x 100",
          "Standing Fan Unit x 6",
          "Mobile Toilet Unit x 1",
        ],
        "Taoist Funeral Setup",
        "Photo Enlargement",
        [
          "One Enlargement Photo 10″x12″",
          "Photo Frame 10″ x 12″ Unit x 1",
          "Passport Sized Photo Unit x 6",
        ],
        "Floral Arrangement",
        ["Photo Frame Wreath Unit x 1", "Deceased Table Unit x 2"],
        "For Recording Purpose",
        [
          "Condolence Book Unit x 1",
          "Plastic Folder Unit x 1",
          "Ball Point Pen Unit x 1",
        ],
      ],
    },
    {
      heading: "Coordination of Funeral Ceremony",
      items: [
        "Taoist Funeral Ceremonial Items",
        ["Priest Chanting Services", "Encoffin", "Last Night", "Funeral Day"],
        "Paper Products",
        [
          "Paper House Unit x 1",
          "Gold Mountain Unit x 1",
          "Silver Mountain Unit x 1",
          "Black Treasure Chest Unit x 1",
          "Red Clothing Chest Unit x 1",
          "Guy Paper Servant Unit x 1",
          "Girl Paper Servant Unit x 1",
        ],
        "Funeral Lanterns with Surname & Age Inscription",
        "Air Conditioned Bus",
        "Funeral Hearse",
        "Ashes Collection Services",
      ],
    },
  ],
};

const christianFuneralPackage: ServicePackage = {
  id: "christian-funeral-packages",
  name: "Christian Funeral Package",
  description: "3 Days Basic Package $4500",
  includes: [
    {
      heading: "Casket and Embalming",
      items: [
        "Half-Glass Casket",
        "Casket Furnishing",
        [
          "Plate Bearing Deceased's Name",
          "Plate Bearing Deceased's DOB & Date of Passing",
          "Cross",
        ],
        "Embalming by Licensed Female Embalmer",
        ["Showering for Deceased", "Make-up for Deceased"],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers in Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        [
          "Air Coolers x 2 Units",
          "Purposeful Furnishing",
          "Warmth Lighting Ambiance",
          "Christian Themed Backdrop",
        ],
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Church Service",
      items: [
        "Flower Arrangement x 1 Units",
        "Photo Frame Wreath x 1 Unit",
        "Name Plate Bearing Deceased's name, DOB & Date of Passing",
      ],
    },
    {
      heading: "Other Essential",
      items: [
        "Coordination with Church for Prayer Service (Some Church only accept immediate family members to make arrangements)",
        "PA System",
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Guest Reception Area Setup",
        ["Condolence Book", "Safe Box", "Stationery Set", "Funeral Notice"],
        "Safe Entry Setup",
        ["Infrared Thermometer", "Safe Entry Check-In"],
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Passport Size Photo Keepsake x 6 Units",
        "Ash Collection Service",
        "Post Funeral Guide",
      ],
    },
  ],
};

const catholicFuneralPackage: ServicePackage = {
  id: "3-day-basic-package",
  name: "Catholic Funeral Package",
  description: "3 Days Basic Package $4500",
  includes: [
    {
      heading: "Casket and Embalming",
      items: [
        "Half-Glass Casket",
        "Casket Furnishing",
        [
          "Plate Bearing Deceased's Name",
          "Plate Bearing Deceased's DOB & Date of Passing",
          "Crucifix",
        ],
        "Embalming by Licensed Female Embalmer",
        ["Showering for Deceased", "Make-up for Deceased"],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers in Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        "Air Coolers x 2 Units",
        "Purposeful Furnishing",
        "Warmth Lighting Ambiance",
        "Catholic Themed Backdrop",
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Memorial Altar",
      items: [
        "Flower Arrangement x 1 Unit",
        "Photo Frame Wreath x 1 Unit",
        "Name Plate Bearing Deceased's Name, DOB & Date of Passing",
        "Bottle of Holy Water",
      ],
    },
    {
      heading: "Funeral Mass Service",
      items: [
        "Coordination with Parishioner for Funeral Mass (Some Churches only accept immediate family members to make arrangements)",
      ],
    },
    {
      heading: "Other Essentials",
      items: [
        "Guest Reception Area Setup",
        ["Condolence Book", "Safe Box", "Stationery Set", "Funeral Notice"],
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Passport Size Photo Keepsake x 6 Units",
        "Ash Collection Service",
        "Post Funeral Guide",
      ],
    },
  ],
};

const directCremationFuneralPackage: ServicePackage = {
  id: "3-days-basic-package",
  name: "Direct Cremation Package",
  description: "3 Days Basic Package $5500",
  includes: [
    {
      heading: "Casket and Embalming",
      items: [
        "Half Glass Casket",
        [
          "Casket Furnishing",
          "Pillow",
          "Sutra Blanket",
          "Sandal Wood",
          "Sandal Powder",
          "Cotton Roll",
        ],
        "Embalming by Licensed Female Embalmer",
        [
          "Showering of Deceased",
          "Make-up for Deceased",
          "Dressing for Deceased",
        ],
      ],
    },
    {
      heading: "Professional Care Team",
      items: [
        "Shifting Deceased from Hospital or Home",
        "Transporting Deceased to Embalming Facility",
        "Sending Deceased to Wake Venue",
        "Booking of Cremation Slot and Arrangements",
        "Professional Service Team on Funeral Day",
        "3 Session Revered Monk Prayer (Encoffinment + Final Night + Funeral Day)",
        "Glass Hearse (Funeral Day)",
        "Pall-Bearers In Suits",
      ],
    },
    {
      heading: "Canvas Tentage",
      items: [
        "Void Deck Enclosed x 1 Unit",
        "Round Tables x 10 Units",
        "Square Tables x 15 Units",
        "Chairs x 100 Units",
        "Curtain & Carpet In Casket Enclosed",
        "Air Coolers x 2 Units",
        "Purposeful Furnishing",
        "Warmth Lighting Ambiance",
        "Buddhist Themed Backdrop",
        "Ceiling Fan x 4 Units",
        "Large Standing Fan x 2 Units",
        "General Lighting",
        "Mobile Toilet",
      ],
    },
    {
      heading: "Memorial Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "1 Enlarged Photo Size 10″(W) X 12″(H)",
        "6 pcs Passport Size Photo",
        "Incense Pot",
        "Joss Sticks (Small / Medium / Large)",
        "Ancestral Tablet",
        "Deceased Chair",
        "Vegetarian Food Offering x 3 Sets",
        "Oil Lamp",
        "Red Gel Candles",
      ],
    },
    {
      heading: "Buddha Altar",
      items: [
        "Flower Arrangement x 2 Units",
        "Traditional Chinese Lamp",
        "Buddha Statue",
        "Food & Fruits Offering",
        "Buddha Incense Pot",
        "Incense Coil",
        "Lotus Gel Candles",
        "Glass Cup",
        "Oil Lamp",
      ],
    },
    {
      heading: "Other Essential",
      items: [
        "Guest Reception Area Setup",
        "Condolence Book",
        "Safe Box",
        "Stationery Set",
        "Funeral Notice",
        "45 Seater Air-condition Coach (2-way)",
        "Floral Water for Guests",
        "Appreciation Token for Guests",
        "Passport Size Photo Keepsake",
        "Ash Collection Service",
        "Post Funeral Guide",
        "Mobile Toilet",
        "Ash Collection Service with Complimentary Red Cloth and Paper Umbrella",
      ],
    },
  ],
};

// services

const buddhistFuneralService: Service = {
  id: "buddhist-funeral-service",
  image: "/services/buddhist.webp",
  name: "Buddhist Funeral Services",
  description:
    "Buddhist funeral rites with chanting led by monks to assist the deceased in transitioning towards the rebirth.",
  slug: "buddhist-funeral-service",
  about: {
    image: "/services/buddhist-about.webp",
    heading: "About Buddhist Funeral",
    body: "In Buddhism, death is considered a highly significant event for both the person who has passed away and those who are left behind. For the deceased, it represents the start of a transition into a new phase of existence within the cycle of rebirth.",
  },
  packages: [buddhistFuneralPackage],
  gallery: [
    "/services/gallery/buddhist-taoist-1.webp",
    "/services/gallery/buddhist-taoist-2.webp",
    "/services/gallery/buddhist-taoist-3.webp",
    "/services/gallery/buddhist-taoist-4.webp",
    "/services/gallery/buddhist-taoist-5.webp",
    "/services/gallery/buddhist-taoist-6.webp",
  ],
};

const taoistFuneralService: Service = {
  id: "taoist-funeral-service",
  image: "/services/taoist.webp",
  name: "Taoist Funeral Services",
  slug: "taoist-funeral-service",
  description:
    "Taoist Funeral can be complicated due to the different dialect groups and custom requirements. Let us help you with it.",
  about: {
    image: "/services/taoist-about.webp",
    heading: "About Taoist Funeral",
    body: "During Taoist funeral rituals, the Taoist priest will guide the family and relatives in a ceremonial procession. Additionally, the head of the family will carry the deceased's photograph throughout the funeral march.",
  },
  packages: [taoistFuneralPackage],
  gallery: [
    "/services/gallery/buddhist-taoist-1.webp",
    "/services/gallery/buddhist-taoist-2.webp",
    "/services/gallery/buddhist-taoist-3.webp",
    "/services/gallery/buddhist-taoist-4.webp",
    "/services/gallery/buddhist-taoist-5.webp",
    "/services/gallery/buddhist-taoist-6.webp",
  ],
};

const christianFuneralService: Service = {
  id: "christian-funeral-service",
  image: "/services/christian.webp",
  name: "Christian Funeral Services",
  slug: "christian-funeral-service",
  description:
    "Christian Funeral are mostly in celebration of one's life and hence a more cheerful and hopeful setting are often observed in a Christian funeral.",
  about: {
    image: "/services/christian-about.webp",
    heading: "About Christian Funeral ",
    body: "Christian funeral celebrates the life of the dearly departed. With pastor or church minister leading the service, prayers and singing. Providing comfort and hope to the bereaved.",
  },
  packages: [christianFuneralPackage],
  gallery: [
    "/services/gallery/christian-catholic-1.webp",
    "/services/gallery/christian-catholic-2.webp",
    "/services/gallery/christian-catholic-3.webp",
    "/services/gallery/christian-catholic-4.webp",
    "/services/gallery/christian-catholic-5.webp",
    "/services/gallery/christian-catholic-6.webp",
  ],
};

const catholicFuneralService: Service = {
  id: "catholic-funeral-service",
  image: "/services/catholic.webp",
  name: "Catholic Funeral Services",
  slug: "catholic-funeral-service",
  description:
    "Catholics are more inclined to use pictures of Jesus for their backdrop. We also help coordinate the Funeral Mass on the wake's final day.",
  about: {
    image: "/services/catholic-about.webp",
    heading: "About Catholic Funeral",
    body: "A catholic funeral is a religion service that's held for family and friends to come together to pray for the dealy departed soul, celebrate its life and memories.",
  },
  packages: [catholicFuneralPackage],
  gallery: [
    "/services/gallery/christian-catholic-1.webp",
    "/services/gallery/christian-catholic-2.webp",
    "/services/gallery/christian-catholic-3.webp",
    "/services/gallery/christian-catholic-4.webp",
    "/services/gallery/christian-catholic-5.webp",
    "/services/gallery/christian-catholic-6.webp",
  ],
};

const directCremationFuneralService: Service = {
  id: "direct-cremation-funeral-service",
  image: "/services/direct-cremation.webp",
  name: "Direct Cremation Funeral Services",
  slug: "direct-cremation-service",
  description:
    "Suitable for small families or those who do not wish to have a funeral wake, direct cremation package offer a short memorial instead",
  about: {
    image: "/services/direct-cremation-about.webp",
    heading: "About Direct Cremation",
    body: "Direct cremation are for those whose wishes a simple funeral without any religion service. Suitable for smaller families and those who prefer without a wake.",
  },
  packages: [directCremationFuneralPackage],
  gallery: [],
};

export const services: Service[] = [
  buddhistFuneralService,
  taoistFuneralService,
  christianFuneralService,
  catholicFuneralService,
  directCremationFuneralService,
];
